import defaultConfig from './default.json';
import productionConfig from './production.json';
import stagingConfig from './staging.json';

const env = process.env.REACT_APP_ENV ?? process.env.NODE_ENV;

const configs = {
  development: defaultConfig,
  production: productionConfig,
  staging: stagingConfig,
};

export default configs[env];
